import React from 'react';
import { QueryClientProvider } from 'react-query';
import { useLocation } from 'react-router-dom';

import { Box } from '@mui/material';

import { useAuthContext } from 'contexts/AuthContext';
import { useCheckUserRoles } from 'hooks';
import { ROLES_LIST } from '../Auth/authConfig';
import { queryClient } from 'config/queryClient';

import { ErrorBoundary } from 'components/CommonComponents/ErrorBoundary';
import { LoadingPlaceholder } from 'components/CommonComponents/LoadingPlaceholder';

import './App.css';

const AdminRoutes = React.lazy(() => import('./AdminRoutes'));
const ProtectedRoutes = React.lazy(() => import('./ProtectedRoutes'));
const PublicRoutes = React.lazy(() => import('./PublicRoutes'));
const SignIn = React.lazy(() => import('pages/SignIn'));

export default function App() {
  const { isInitialized, isPublicRoute } = useAuthContext();
  const checkUserRoles = useCheckUserRoles();
  const location = useLocation();

  const isAdminPage = React.useMemo(() => {
    return location.pathname.startsWith('/admin/') || location.pathname.startsWith('/admin');
  }, [location, location.pathname]);

  if (isInitialized === undefined) {
    return (
      <Box pt='20vh'>
        <LoadingPlaceholder />
      </Box>
    );
  }

  const fallback = (
    <Box pt='20vh'>
      <LoadingPlaceholder />
    </Box>
  );

  return (
    <QueryClientProvider client={queryClient}>
      <ErrorBoundary>
        <React.Suspense fallback={fallback}>
          {isPublicRoute ? <PublicRoutes /> : <>
            {isInitialized ? <>
              {isAdminPage && checkUserRoles([ROLES_LIST.ROLE_KIMEP_ADMIN])
                ? <AdminRoutes />
                : <ProtectedRoutes />}
            </> : <SignIn />}
          </>}
                    
        </React.Suspense>
      </ErrorBoundary>
    </QueryClientProvider>
  );
}
